import type { PropsWithChildren } from 'react';
import type { ReactNodeLike } from 'prop-types';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { UseToastProvider } from '@motorway/motorway-storybook-cra';
import { Toaster } from '@motorway/mw-highway-code/pre-alpha';

import { MirageWrapper } from 'Components/Mirage/MirageWrapper';
import { OptimizelyInitialiser } from 'Components/OptimizelyInitialiser/OptimizelyInitialiser';
import { ReactQueryDevtools } from 'Components/ReactQueryDevtools/ReactQueryDevtools';
import RouterMiddleware from 'Components/RouterMiddleware/RouterMiddleware';
import { SaleTimeSynchroniser } from 'Components/SaleTime/SaleTimeSynchroniser';
import { ZendeskWidget } from 'Components/ZendeskWidget/ZendeskWidget';
import { queryClient, reactQuerySessionPersister } from 'Config/reactQueryConfig';
import { AnalyticsGlobalContext } from 'Context/AnalyticsGlobalContext';
import { AppContextProvider } from 'Context/AppContext';
import { BiddingRealTimeProvider } from 'Context/BiddingContext';
import { BreakpointContextProvider } from 'Context/BreakpointsContext/BreakpointsContext';
import { FeaturesContextProvider } from 'Context/FeaturesContext/FeaturesContext';
import { LocationProvider } from 'Context/LocationContext/LocationContext';
import type { FeatureFlags } from 'Services/dataProvider.types';
import type { ConfigDatafile } from 'Services/optimizely/optimizely';
import { useInitSnowplow } from 'Services/snowplow/useInitSnowplow';
import FeesStoreLoader from 'Stores/FeesStore/FeesStoreLoader';
import ShortlistStoreLoader from 'Stores/ShortlistStore/ShortlistStoreLoader';
import { StoresProvider } from 'Stores/StoresContext/StoresContext';
import type { StoresProviderInitialValues } from 'Stores/StoresContext/StoresContext.types';
import type { ListType } from 'Types/listType';
import type { Makes } from 'Types/make';
import type { Models } from 'Types/model';
import type { SaleTimes } from 'Types/saleTimes';
import type { User } from 'Types/user';
import { useDerivedActionRouteObserver } from 'Utilities/hooks/useDerivedActionState/useDerivedActionState';

import { BrazeProvider } from './BrazeContext/BrazeContext';

interface PageProps {
	filters?: StoresProviderInitialValues['filters'];
	query: StoresProviderInitialValues['query'];
}

interface AppProvidersProps {
	children: NonNullable<ReactNodeLike>;
	cookies: Record<string, string>;
	featureFlags: FeatureFlags;
	listType: ListType;
	location: Location;
	makes: Makes;
	models: Models;
	optimizelyDatafile: ConfigDatafile;
	pageProps?: PageProps;
	saleTimes: SaleTimes;
	user?: User;
}

export const AppProviders: React.FC<PropsWithChildren<AppProvidersProps>> = ({
	children,
	cookies,
	featureFlags = {},
	listType,
	location,
	makes,
	models,
	optimizelyDatafile,
	pageProps = {} as PageProps,
	saleTimes,
	user,
}) => {
	const { filters, query } = pageProps;
	const storeInitialValues = { filters, listType, makes, models, query, saleTimes };

	useInitSnowplow(user);

	useDerivedActionRouteObserver();

	return (
		<PersistQueryClientProvider client={queryClient} persistOptions={{ persister: reactQuerySessionPersister }}>
			<StoresProvider initialValues={storeInitialValues}>
				<UseToastProvider>
					<BreakpointContextProvider>
						<AppContextProvider user={user}>
							<LocationProvider location={location}>
								<FeaturesContextProvider {...{ cookies, featureFlags }}>
									<RouterMiddleware>
										<MirageWrapper>
											<OptimizelyInitialiser optimizelyDatafile={optimizelyDatafile} user={user}>
												<ShortlistStoreLoader />
												<FeesStoreLoader />
												<ReactQueryDevtools />
												<Toaster />
												<SaleTimeSynchroniser />
												<AnalyticsGlobalContext user={user} />
												<ZendeskWidget />
												<BiddingRealTimeProvider>
													<BrazeProvider>{children}</BrazeProvider>
												</BiddingRealTimeProvider>
											</OptimizelyInitialiser>
										</MirageWrapper>
									</RouterMiddleware>
								</FeaturesContextProvider>
							</LocationProvider>
						</AppContextProvider>
					</BreakpointContextProvider>
				</UseToastProvider>
			</StoresProvider>
		</PersistQueryClientProvider>
	);
};
