import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';

import { baseGAEvent } from 'Services/gtm';
import { AccountSlugs } from 'Types/account';

export const ANALYTICS_PROVIDERS = {
	GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
	SNOWPLOW: 'SNOWPLOW',
} as const;

type ANALYTICS_PROVIDERS_ENUM = keyof typeof ANALYTICS_PROVIDERS;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ANALYTICS_PROVIDER_EVENT_TRIGGER: Record<ANALYTICS_PROVIDERS_ENUM, (...args: any[]) => void> = {
	GOOGLE_ANALYTICS: baseGAEvent,
	SNOWPLOW: trackSelfDescribingEvent,
};

export const SNOWPLOW_SCHEMA_BASE_URL = 'iglu:uk.co.motorway';

export const SNOWPLOW_EVENTS = {
	BACKEND_TRIGGER: {
		SCHEMA: 'backend_trigger',
		VERSION: '1-0-1',
	},
	CLICK: {
		SCHEMA: 'click',
		VERSION: '2-0-0',
	},
	CTA_CLICKED: {
		SCHEMA: 'cta_clicked',
		VERSION: '5-0-1',
	},
	ENQUIRY: {
		SCHEMA: 'enquiry',
		VERSION: '1-0-0',
	},
	HOVER: {
		SCHEMA: 'hover',
		VERSION: '2-0-1',
	},
	LOAD: {
		SCHEMA: 'load',
		VERSION: '1-0-0',
	},
	OPTIMIZELY_DECISION: {
		SCHEMA: 'optimizely_decision',
		VERSION: '1-0-0',
	},
	OPTIMIZELY_EVENT: {
		SCHEMA: 'optimizely_event',
		VERSION: '1-0-0',
	},
	PLT_VALUATION: {
		SCHEMA: 'plt_valuation',
		VERSION: '1-0-0',
	},
	POPUP_APPEAR: {
		SCHEMA: 'popup_appear',
		VERSION: '1-0-0',
	},
	PRO_SIMILAR_VEHICLES_CAROUSEL: {
		SCHEMA: 'pro_similar_vehicles_carousel',
		VERSION: '1-0-1',
	},
	PRO_VEHICLE_LIST: {
		SCHEMA: 'pro_vehicle_list',
		VERSION: '1-0-1',
	},
	SCROLL: {
		SCHEMA: 'scroll',
		VERSION: '2-0-0',
	},
	SMV_UPPERFUNNEL_PLT_OFFER_SELECTED: {
		SCHEMA: 'smv_upperfunnel_plt_offer_selected',
		VERSION: '1-0-0',
	},
	TOGGLE_CLICK: {
		SCHEMA: 'toggle_click',
		VERSION: '2-0-1',
	},
	USER_DEALER_CONTACT: {
		SCHEMA: 'user_dealer_contact',
		VERSION: '2-0-0',
	},
} as const;

export const SNOWPLOW_BACKEND_ENTITIES = {
	DOWNLOAD: 'download',
	PAYMENTS: 'mw-payments',
};

export const SNOWPLOW_BACKEND_SERVICES = {
	CHECK_FUNDS: 'check funds',
	MARKETPLACE: 'marketplace',
	OTP_INCORRECT_PASSWORD_ATTEMPT_NUMBER: 'otp incorrect password attempt number',
	OTP_NEW_REQUEST: 'otp new request',
	OTP_SUCCESSFUL: 'otp successful',
	PAYMENT_SUCCESS: 'payment success',
	PURCHASES_COMPLETE: 'dlr_purchases_complete',
	TRANSACTION_DETAILS_MODAL: 'Transaction details modal',
};

export const ANALYTICS_EVENT_LOGGER_STYLES = {
	[ANALYTICS_PROVIDERS.SNOWPLOW]: 'background-color: #3498db; color: white; padding: 2px 5px; border-radius: 3px;',
	[ANALYTICS_PROVIDERS.GOOGLE_ANALYTICS]:
		'background-color: #27ae60; color: white; padding: 2px 5px; border-radius: 3px;',
};

export const ANALYTICS_CATEGORIES = {
	ACCOUNT: {
		BIDDING_AND_PURCHASE_HISTORY: 'Bidding and purchase history',
		[AccountSlugs.ACTIVE]: 'Bids & Offers - Active',
		[AccountSlugs.CANCELLED]: 'Purchases - Cancelled',
		[AccountSlugs.COMPLETE]: 'Purchases - Complete',
		[AccountSlugs.DIDNT_WIN]: 'Bids & Offers - Didnt win',
		[AccountSlugs.UNDER_OFFER]: 'Bids & Offers - Under offer',
		PURCHASES_COMPLETE: 'Purchases complete',
	},
	ACCOUNT_SETTINGS: 'Account settings',
	ADVISORY: 'Advisory',
	BIDDING: 'Bidding',
	EXTERIOR_GRADING: 'Exterior grading',
	GALLERY: 'Gallery',
	HISTORY_CHECK: 'History check',
	HOMEPAGE: 'Homepage',
	INSIGHTS: 'Insights',
	KEEPER_DETAILS: 'Keeper details',
	KYC: 'KYC',
	MARKETPLACE: {
		ADD_SAVED_SEARCH: 'Add saved search',
		DELETE_SAVED_SEARCH: 'Delete saved search',
		DOWNLOADABLE_VEHICLE_LIST: 'Downloadable vehicle list',
		FILTERING: 'Filtering',
		GALLERY: 'Gallery',
		MANAGE_SAVED_SEARCH: 'Manage saved search',
		SAVED_FILTERS_MODAL: 'Saved filters modal',
		SAVED_SEARCH_FILTER_ACTIVE: 'Saved search filter/active',
		SAVED_SEARCH_LIST: 'Saved search list',
		SAVED_SEARCH_PILL: 'Saved search pill',
		SAVED_SEARCHES: 'Saved searches',
		SHORTLIST: 'Shortlist',
		SORTING: 'Sorting',
		VEHICLE_LIST: 'Vehicle list',
	},
	MILEAGE_CHECK: 'Mileage check',
	MOTORWAY_MOVE_PAGE: 'Motorway Move page',
	MOTORWAY_TRADE_PAGE: 'Motorway Trade page',
	NAVIGATION: 'Navigation',
	PAYMENTS: {
		MOTORWAY_PAY_MODULR: 'Motorway Pay Modulr',
		MOTORWAY_PAY_PAGE: 'Motorway Pay page',
		OTP: 'OTP',
		PAYMENT: 'Vehicle payment',
		PAYMENT_INSUFFICIENT_FUNDS: 'Vehicle payment insufficient funds',
		PAYMENT_TRANSACTION_STATEMENT: 'Transaction statement',
		PAYMENT_WALLET: 'Payment wallet',
		PAYMENT_WALLET_TRANSACTIONS: 'Payment wallet transactions',
		PAYMENT_WALLET_WITHDRAW: 'Payment wallet withdrawals',
	},
	RECOMMENDED_VEHICLES: 'Recommended vehicles',
	RECOMMENDER: 'Recommender',
	REPORTING_INSIGHTS: 'Reporting insights',
	SELLER_DETAILS: 'Seller details',
	SIGN_IN_PAGE: 'Sign In page',
	SIGN_UP_FORM: 'Sign Up Form',
	SITEWIDE_BANNER: 'Sitewide banner',
	SITEWIDE_FOOTER: 'Sitewide footer',
	VEHICLE_DELIVERY: 'Vehicle delivery',
	VEHICLE_DETAILS: 'Vehicle details',
} as const;
