/* eslint-disable local-rules/interfaces-types-properties-naming */
import type { ReactSDKClient } from '@optimizely/react-sdk';
import { createInstance, enums } from '@optimizely/react-sdk';

import {
	triggerOptimizelyDecisionEvent,
	triggerOptimizelyTrackingEvent,
} from 'Services/analytics/events/optimizelyEvents';
import { logger } from 'Services/logger/logger';

export type ConfigDatafile = object | string;

export type OptimizelyDecisionEventProps = {
	decision: string;
	flag_enabled: boolean;
	flag_name: string;
	optimizely_user_id: string;
	rule_name: string;
};

export type OptimizelyTrackEventProps = {
	datafile_version: string;
	event_name: string;
	optimizely_user_id: string;
	sdk_name: string;
};

const OPTIMIZELY_EVENT = {
	DECISION: (data: OptimizelyDecisionEventProps) => triggerOptimizelyDecisionEvent(data),
	TRACK: (data: OptimizelyTrackEventProps) => triggerOptimizelyTrackingEvent(data),
};

export type OnDecisionProps = {
	decisionInfo: {
		decisionEventDispatched: boolean;
		enabled: boolean;
		flagKey: string;
		reasons: string[];
		ruleKey: string;
		variables: object;
		variationKey: string;
	};
	type: string;
	userId: string;
};

export const onDecision = ({ decisionInfo, type, userId }: OnDecisionProps): void => {
	if (type === 'flag' && decisionInfo?.decisionEventDispatched && decisionInfo?.enabled) {
		OPTIMIZELY_EVENT.DECISION({
			decision: decisionInfo.variationKey,
			flag_enabled: decisionInfo.enabled,
			flag_name: decisionInfo.flagKey,
			optimizely_user_id: userId,
			rule_name: decisionInfo.ruleKey,
		});
	}
};

export type OnTrackProps = {
	eventKey: string;
	eventTags?: unknown;
	logEvent: {
		httpVerb: string;
		params: {
			account_id: string;
			client_name: string;
			client_version: string;
			enrich_decisions: boolean;
			project_id: string;
			revision: string;
			visitors: unknown;
		};
		url: string;
	};
	userId: string;
};

export const onTrack = ({ eventKey, logEvent, userId }: OnTrackProps): void => {
	OPTIMIZELY_EVENT.TRACK({
		datafile_version: logEvent.params.revision,
		event_name: eventKey,
		optimizely_user_id: userId,
		sdk_name: logEvent.params.client_name,
	});
};

export const optimizelyLogger = (_: unknown, message: string): void => {
	logger.warn({
		message: `[Optimizely] - ${message}`,
		scope: 'optimizelyInstanceFactory',
	});
};

export const optimizelyInstanceFactory = (datafile: ConfigDatafile): ReactSDKClient =>
	createInstance({
		datafile,
		eventBatchSize: 10,
		eventFlushInterval: 1000,
		logger: {
			log: optimizelyLogger,
		},
		logLevel: enums.LOG_LEVEL.WARNING,
		odpOptions: {
			disabled: true,
		},
	});

interface GetUserAttributesPayloadParams {
	dealerContactId?: number;
	dealershipId?: number;
}

export const getUserAttributesPayload = ({
	dealerContactId,
	dealershipId,
}: GetUserAttributesPayloadParams): Record<string, Nullable<string>> => {
	if (!dealerContactId || !dealershipId) {
		return {};
	}

	return {
		$opt_bucketing_id: `${dealershipId}`,
		dealership_and_contact_id_concat: `${dealershipId}_${dealerContactId}`,
		dealership_id: `${dealershipId}`,
	};
};
