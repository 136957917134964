import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { useBreakpoint } from 'Context/BreakpointsContext/BreakpointsContext';
import { logger } from 'Services/logger/logger';
import { handleAggregatedError } from 'Utilities/error/errors';
import isClientSideAuth from 'Utilities/helpers/isClientSideAuth';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useIsAuth from 'Utilities/hooks/useIsAuth';
import useUser from 'Utilities/hooks/useUser';

import {
	PAGES_TO_HIDE_ON_NON_DESKTOP,
	ZENDESK_SCRIPT_ID,
	ZENDESK_WIDGET_VISIBILITY_EVENT,
	ZENDESK_Z_INDEX,
} from './ZendeskWidget.consts';
import {
	handleZendeskWidgetLogin,
	handleZendeskWidgetLogout,
	handleZendeskWidgetVisibility,
	handleZendeskWidgetZIndex,
	ZendeskKey,
} from './ZendeskWidget.helpers';
import { useZendeskChatHistoryLoginToken } from './ZendeskWidget.queries';

export const useZendeskChatHistory = (isAuthed: boolean) => {
	const user = useUser();
	const { data, remove } = useZendeskChatHistoryLoginToken({ isAuthed, userId: user?.id });

	useEffect(() => {
		const token = data?.data;
		if (token && isAuthed) {
			handleZendeskWidgetLogin(token);
		}

		return () => {
			if (!isAuthed) {
				/**
				 * TODO: There is a larger issue where AppContext (useUser hook) on different tabs/windows are not in sync
				 * This is a patch fix where we remove the cache key when the user isn't Authed anymore
				 * This will cause the hook to re-fetch the token when the user logs in again regardless of the account they login with
				 *
				 * The issue is that the cache key will say it's for user 1 but the token in the response is for user 2 because the server has
				 * the correct user but the client (AppContext) has the old user.
				 *
				 * Due to this, the cache key in the dev tools will show the wrong user but the token will be correct
				 * */
				remove();
				handleZendeskWidgetLogout();
			}
		};
	}, [data?.data, isAuthed, remove]);
};

export const useHideOnPageAndBreakpoint = (isAuthed: boolean) => {
	const {
		minWidth: { breakpointTablet: isDesktop },
	} = useBreakpoint();
	const router = useRouter();
	const currentPage = router.pathname;

	const shouldHideOnPageAndBreakpoint = useMemo(
		() => !isDesktop && (PAGES_TO_HIDE_ON_NON_DESKTOP as string[]).includes(currentPage),
		[currentPage, isDesktop],
	);

	// Effect for handling hiding pages when it comes to mobile/tablet view
	useEffect(() => {
		if (!isAuthed) {
			return;
		}

		handleZendeskWidgetVisibility(shouldHideOnPageAndBreakpoint ? 'hide' : 'show');
	}, [isAuthed, shouldHideOnPageAndBreakpoint]);

	return shouldHideOnPageAndBreakpoint;
};

export const useHideOnVisibilityChange = (isAuthed: boolean) => {
	// Effect for handling visibility change but user logged out on one tab etc
	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				handleZendeskWidgetVisibility(isAuthed ? 'show' : 'hide');
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [isAuthed]);
};

export const useZendeskWidgetInjector = (isDealerLiveChatEnabled: boolean) => {
	const [isZenDeskWidgetLoaded, setIsZenDeskWidgetLoaded] = useState(false);

	const handleError = useCallback((err: string) => {
		setIsZenDeskWidgetLoaded(false);
		logger.error({
			context: {
				cause: err,
			},
			error: handleAggregatedError(err, [new Error(err)]),
			message: err,
			scope: 'useZendeskWidgetInjector',
		});
	}, []);

	const createZendeskWidget = useCallback(() => {
		const existingScript = document.getElementById(ZENDESK_SCRIPT_ID);
		if (existingScript) return;

		const s = document.createElement('script');
		s.id = ZENDESK_SCRIPT_ID;
		s.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZendeskKey}`;
		s.type = 'text/javascript';
		s.onload = () => {
			setIsZenDeskWidgetLoaded(true);
		};
		s.onerror = () => handleError('Zendesk widget script has failed to load');

		document.head.append(s);
	}, [handleError]);

	useEffect(() => {
		if (!isZenDeskWidgetLoaded && isDealerLiveChatEnabled) {
			createZendeskWidget();
		}
	}, [createZendeskWidget, isZenDeskWidgetLoaded, isDealerLiveChatEnabled]);

	return isZenDeskWidgetLoaded;
};

export const useZendeskWidget = () => {
	const isDealerLiveChatEnabled = useFeatureToggle(FEATURES.dealerLiveChatChatBot);
	const isZenDeskWidgetLoaded = useZendeskWidgetInjector(isDealerLiveChatEnabled);

	const [isWidgetVisible, setIsWidgetVisible] = useState(false);
	const isAuthed = useIsAuth() && isClientSideAuth();

	const isEnabled = isDealerLiveChatEnabled && isAuthed && isZenDeskWidgetLoaded;

	const shouldHide = useHideOnPageAndBreakpoint(isEnabled);
	useHideOnVisibilityChange(isEnabled);
	useZendeskChatHistory(isEnabled);

	useEffect(() => {
		if (isEnabled && !shouldHide) {
			handleZendeskWidgetZIndex(ZENDESK_Z_INDEX);
			handleZendeskWidgetVisibility('show');
		} else {
			handleZendeskWidgetVisibility('hide');
		}
	}, [isEnabled, shouldHide]);

	useEffect(() => {
		const handleIsWidgetVisible = (e: CustomEvent) => {
			setIsWidgetVisible(e.detail.showWidget);
		};
		window.addEventListener(ZENDESK_WIDGET_VISIBILITY_EVENT, handleIsWidgetVisible);
		return () => {
			window.removeEventListener(ZENDESK_WIDGET_VISIBILITY_EVENT, handleIsWidgetVisible);
		};
	}, []);

	return { isWidgetVisible };
};
