import { LauncherButton } from './ZendeskWidgetLauncher/LauncherButton';
import { useZendeskWidget } from './ZendeskWidget.hooks';

export const ZendeskWidget = () => {
	const { isWidgetVisible } = useZendeskWidget();

	if (!isWidgetVisible) {
		return null;
	}

	return <LauncherButton />;
};
