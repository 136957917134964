import { useEffect, useState } from 'react';

import { handleZendeskUnreadMessages } from '../ZendeskWidget.helpers';

import styles from './UnreadMessagesIndicator.module.scss';

interface UnreadMessagesIndicatorProps {
	isOpen: boolean;
}

export const UnreadMessagesIndicator = ({ isOpen }: UnreadMessagesIndicatorProps) => {
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
	useEffect(() => {
		handleZendeskUnreadMessages(setUnreadMessagesCount);
	}, []);

	useEffect(() => {
		if (isOpen) {
			setUnreadMessagesCount(0);
		}
	}, [isOpen]);

	const hasUnreadMessages = unreadMessagesCount > 0;

	if (!hasUnreadMessages) {
		return null;
	}
	return (
		<span className={styles.unreadMessagesIndicator} role="status">
			{unreadMessagesCount}
		</span>
	);
};
