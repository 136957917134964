import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { logger } from 'Services/logger/logger';

import { ZENDESK_STALE_TIME } from './ZendeskWidget.consts';

interface UseZendeskChatHistoryLoginTokenParams {
	isAuthed: boolean;
	userId: number;
}

export const useZendeskChatHistoryLoginToken = ({ isAuthed, userId }: UseZendeskChatHistoryLoginTokenParams) =>
	useQuery({
		enabled: isAuthed,
		onError: (e) => {
			const message = 'There was an issue with fetching the Zendesk token';
			const error = e instanceof Error ? e : new Error(message);
			logger.error({ error, message, scope: 'useZendeskChatHistoryLoginToken' });
		},
		queryFn: () => axios.get('/api/zendesk-token'),
		queryKey: [`zendesk-token-${userId}`],
		refetchOnWindowFocus: false,
		staleTime: ZENDESK_STALE_TIME, // sync stale time with env for expired token
	});
