import type { NextApiResponse, NextPageContext } from 'next';
import Router from 'next/router';

import { logger } from 'Services/logger/logger';
import ROUTES from 'Utilities/routes';

type DestinationPath = string | string[] | undefined;

export const isDestinationSameAsOrigin = (destinationPath: string): boolean =>
	new URL(document.baseURI).origin === new URL(destinationPath, document.baseURI).origin;

export const validateRedirectDestination = (destinationPath: DestinationPath): { valid: boolean } => ({
	valid: typeof destinationPath === 'string' && isDestinationSameAsOrigin(destinationPath),
});

export const createBrowserOnlyRedirectPath = (
	destinationPath: DestinationPath,
	fallback: string = ROUTES.INDEX.href,
): string => {
	const hostURL = `${window.location.protocol}//${window.location.host}`;
	const resolvedFallback = validateRedirectDestination(fallback).valid ? fallback : ROUTES.INDEX.href;

	const { valid } = validateRedirectDestination(destinationPath);

	return `${hostURL}${valid ? destinationPath : resolvedFallback}`;
};

export const isomorphicRedirect = (ctx: NextPageContext, path: string): void => {
	try {
		if (ctx.isSSR) {
			(ctx.res as NextApiResponse).redirect(307, path);
		} else {
			void Router.replace(path);
		}
	} catch (error) {
		logger.error({
			context: {
				extra: {
					stringifiedError: JSON.stringify(error),
					to: path,
				},
			},
			error: error instanceof Error ? error : new Error('Failed to redirect'),
			message: 'Failed to redirect',
			scope: 'isomorphicRedirect',
		});
	}
};
