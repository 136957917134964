import { FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';
import { getServerBuildId } from 'Services/api/buildId';
import { logger } from 'Services/logger/logger';

import { getIsomorphicCookie, isSSR, stringBooleanToBoolean } from './helpers';
import ROUTES from './routes';

const ALLOWED_ROUTES = [ROUTES.VEHICLE.href, ROUTES.PRICING.href];

interface HandleRefreshIfOutOfSyncBuildParams {
	clientBuildId: string | undefined;
	route: string;
}

export const handleRefreshIfOutOfSyncBuild = async ({
	clientBuildId,
	route,
}: HandleRefreshIfOutOfSyncBuildParams): Promise<void> => {
	try {
		if (isSSR()) {
			logger.warn({
				message: 'handleRefreshIfOutOfSyncBuild should not be called on server side',
				scope: 'handleRefreshIfOutOfSyncBuild',
			});
			return;
		}

		const isForceRefreshOnOutOfSyncBuildEnabled = stringBooleanToBoolean(
			getIsomorphicCookie(FEATURES.forceRefreshOnOutOfSyncBuild),
		);

		if (!isForceRefreshOnOutOfSyncBuildEnabled) {
			return;
		}

		const isAllowedRoute = ALLOWED_ROUTES.some((allowedRoute) => allowedRoute.includes(route));

		if (!isAllowedRoute) {
			return;
		}

		const serverBuildId = await getServerBuildId();

		if (!clientBuildId || !serverBuildId) {
			logger.error({
				context: { extra: { clientBuildId, serverBuildId } },
				message: 'Missing server or client build ID',
				scope: 'handleRefreshIfOutOfSyncBuild',
			});
			return;
		}

		if (clientBuildId !== serverBuildId) {
			window.location.reload();
		}
	} catch (error) {
		if (error instanceof Error) {
			logger.error({
				error,
				message: 'Failed to refresh if out of sync build',
				scope: 'handleRefreshIfOutOfSyncBuild',
			});
		}
	}
};
