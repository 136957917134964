import getConfig from 'next/config';

import ROUTES from 'Utilities/routes';

const { publicRuntimeConfig } = getConfig();
const { ZENDESK_EXPIRES_IN_MS } = publicRuntimeConfig;

export const PAGES_TO_HIDE_ON_NON_DESKTOP = [
	ROUTES.VEHICLE.href,
	ROUTES.VEHICLE_PAYMENT.href,
	ROUTES.WITHDRAW_FUNDS.href,
	ROUTES.DEALER_WALLET.href,
];

// 999 is to ensure we are 1 below the cookie banner, zendesk default is 999999
export const ZENDESK_Z_INDEX = 999;
export const ZENDESK_SCRIPT_ID = 'ze-snippet';
export const ZENDESK_WIDGET_VISIBILITY_EVENT = 'zendeskWidgetVisibility';

const fiveMinsInMs = 5 * 60 * 1000;
export const ZENDESK_STALE_TIME = Number(ZENDESK_EXPIRES_IN_MS) - fiveMinsInMs;
