import type { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { useBreakpoint } from 'Context/BreakpointsContext/BreakpointsContext';
import { triggerNavigationLogoClickedEvent } from 'Services/analytics/events';
import { Menu } from 'Storybook/core/Menu';
import useUser from 'Utilities/hooks/useUser';

import SitewideHeaderLogoLink from './components/SitewideHeaderLogoLink/SitewideHeaderLogoLink';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import { triggerSiteNavigationGAEvent } from './SiteNavigation.analytics';
import type { NavigationWrapperProps, SiteNavigationProps } from './SiteNavigation.types';

import styles from './SiteNavigation.module.scss';

const NavigationWrapper: FC<PropsWithChildren<NavigationWrapperProps>> = ({ children, isDesktop, isUserLoggedIn }) => {
	return (
		<Menu
			className={cx(styles.nav, {
				[styles.loggedIn]: isUserLoggedIn,
				[styles.hideDesktopOnMobileTillHydration]: isDesktop,
			})}
		>
			{children}
		</Menu>
	);
};

const SiteNavigationComponent: FC<SiteNavigationProps> = ({
	hasBottomBorder,
	isSticky,
	isTransparent,
	shouldDisplayXmasLogo,
}) => {
	const user = useUser();
	const router = useRouter();

	const {
		maxWidth: { breakpointTablet: isTablet },
	} = useBreakpoint();

	const isUserLoggedIn = Boolean(user?.dealerName);

	const onLogoContainerClick = () => {
		triggerSiteNavigationGAEvent({ eventName: 'logo', loggedIn: isUserLoggedIn });
		triggerNavigationLogoClickedEvent(!isUserLoggedIn);
	};

	return (
		<div
			data-navigation
			className={cx(
				styles.headerContainer,
				{ [styles.isTransparent]: isTransparent },
				{ [styles.isSticky]: isSticky },
				{ [styles.bottomBorder]: hasBottomBorder },
			)}
			data-is-sticky={isSticky}
		>
			<header className={cx(styles.header)}>
				<div className={styles.logo} onClick={onLogoContainerClick}>
					<SitewideHeaderLogoLink shouldDisplayXmasLogo={shouldDisplayXmasLogo} />
				</div>

				{isTablet ? (
					<NavigationWrapper isUserLoggedIn={isUserLoggedIn}>
						<MobileNavigation router={router} user={user} />
					</NavigationWrapper>
				) : (
					<NavigationWrapper isDesktop isUserLoggedIn={isUserLoggedIn}>
						<DesktopNavigation router={router} user={user} />
					</NavigationWrapper>
				)}
			</header>
		</div>
	);
};

export { SiteNavigationComponent };
