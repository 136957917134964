import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import cx from 'classnames';
import { MDText } from 'i18n-react';
import { useRouter } from 'next/router';

import { Button, CrossIcon } from '@motorway/mw-highway-code';

import { OtpFormContent, OtpFormContentBottom } from 'Components/AuthenticationFeedback/AuthenticationFeedback';
import { triggerSuccessOTPToast } from 'Components/AuthenticationFeedback/AuthenticationFeedback.helpers';
import OTPContent from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent';
import type { OTPContentProps } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import { OtpAction } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import { onRequestCatch } from 'Components/pages/VehiclePayment/components/OTPContent/OTPRequestCatch.helper';
import { getOTPLogParams } from 'Components/pages/VehiclePayment/components/OTPContent/useOTP.helper';
import { GA_OTP_LABEL, OTP_ACTION } from 'Components/pages/VehiclePayment/VehiclePayment.const';
import Texts from 'Components/texts.json';
import {
	CLICK_SCHEMA,
	POPUP_APPEAR_SCHEMA,
	triggerOtpAnalyticsEvent,
} from 'Services/analytics/events/paymentEvents/otpSnowplowEvents';
import { confirmOTPPhoneNumber } from 'Services/api/otp';
import { verifyOTP } from 'Services/api/payment';
import Content from 'Storybook/local/Content/Content';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import { useStoreOtpPhoneValidated } from 'Utilities/hooks/useStoreOtpPhoneValidated';
import useUser from 'Utilities/hooks/useUser';

import type { MFAWrapperProps } from './MFAWrapper.types';

import styles from './MFAWrapper.module.scss';

const LocalT = new MDText(Texts.MFA);

const MFAWrapper = ({
	fullScreen,
	logs = { source: 'accessSensitiveData' },
	onBackClick,
	onSuccess,
	portalId,
}: MFAWrapperProps): JSX.Element => {
	const router = useRouter();
	const { otpPhone, phoneConfirmedAt } = useUser();
	const isOtpSetupFeatureEnabled = useFeatureToggle(FEATURES.paymentsOTP);

	// User object is slow to update, so this store is an early validation check after OTP setup
	const [hasPhoneBeenValidated, setHasPhoneBeenValidated] = useStoreOtpPhoneValidated(false);

	const isMissingOtpPhone = !otpPhone || phoneConfirmedAt === null;
	const [isOTPNumberSetupRequired] = useState(isMissingOtpPhone && isOtpSetupFeatureEnabled && !hasPhoneBeenValidated);

	useEffect(() => {
		if (!isOTPNumberSetupRequired) return;
		triggerOtpAnalyticsEvent({
			customData: { label: GA_OTP_LABEL.OTP_SETUP_SHOWN },
			name: OTP_ACTION.OTP_SETUP_SHOWN,
			schema: POPUP_APPEAR_SCHEMA,
		});
	}, [isOTPNumberSetupRequired]);

	const setUpMFA: OTPContentProps['request'] = async (verificationCode) => {
		try {
			await confirmOTPPhoneNumber({ params: { otpAction: OtpAction.VERIFY_PHONE, verificationCode } });

			triggerSuccessOTPToast();

			return () => {
				setHasPhoneBeenValidated(true);
				onBackClick?.();
			};
		} catch (error) {
			throw await onRequestCatch({ error });
		}
	};

	const logInWithMFA: OTPContentProps['request'] = async (verificationCode) => {
		try {
			await verifyOTP({
				logs: getOTPLogParams(router),
				source: 'accessSensitiveData',
				verificationCode,
			});

			return () => {
				onSuccess();
			};
		} catch (error) {
			throw await onRequestCatch({ error });
		}
	};

	const onBackClickWithEvents = () => {
		triggerOtpAnalyticsEvent({
			customData: { label: GA_OTP_LABEL.OTP_SETUP_CLOSED },
			name: OTP_ACTION.OTP_SETUP_CLOSED,
			schema: CLICK_SCHEMA,
		});
		onBackClick?.();
	};

	const endOfPhone = otpPhone?.slice?.(otpPhone.length - 3, otpPhone.length) || '';
	let contentDesc: ReactNode = `${LocalT.translate('desc', { number: endOfPhone })}`;
	let onOTPContentRequest: OTPContentProps['request'] = logInWithMFA;
	let title = `${LocalT.translate('title')}`;
	let bottomDesc;

	if (isOTPNumberSetupRequired) {
		contentDesc = <OtpFormContent otpPhoneEnd={endOfPhone} />;
		onOTPContentRequest = setUpMFA;
		title = `${LocalT.translate('titleOTP')}`;
		bottomDesc = <OtpFormContentBottom />;
	}

	const Wrapper = fullScreen ? RemoveScroll : React.Fragment;

	const result = (
		<Wrapper>
			<div className={cx(styles.MFAWrapper, { [styles.fullScreen]: fullScreen })} data-testid="MFA-wrapper">
				<Content className={styles.container}>
					{fullScreen && onBackClick && (
						<div className={styles.toolbar}>
							<Button
								fullWidth
								icon={CrossIcon}
								label="close"
								onClick={onBackClickWithEvents}
								showLabel={false}
								variant="tertiary"
							/>
						</div>
					)}

					<div className={styles.content}>
						<OTPContent
							bottomDesc={bottomDesc}
							desc={contentDesc}
							extraStyles={{
								paymentsOTPContent: styles.paymentsOTPContent,
								title: styles.title,
							}}
							logs={logs}
							request={onOTPContentRequest}
							title={title}
						/>
					</div>
				</Content>
			</div>
		</Wrapper>
	);

	if (portalId) {
		const container = document.getElementById(portalId);

		if (container) {
			return ReactDOM.createPortal(result, container);
		}
	}

	return result;
};

export default MFAWrapper;
