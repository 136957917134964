import { useContext } from 'react';

import { AppContext } from 'Context/AppContext';
import { clearCachedState } from 'Utilities/helpers';
import {
	createSignOutMessage,
	windowCommunicationChannel,
} from 'Utilities/windowCommunicationChannel/windowCommunicationChannel.helpers';

type UseSignOutApi = {
	onSignOut: () => Promise<void>;
};

export const useSignOut = (): UseSignOutApi => {
	const appContext = useContext(AppContext);

	const onSignOut = async () => {
		clearCachedState();

		await appContext?.api.signOut();
		windowCommunicationChannel.postMessage(createSignOutMessage(appContext?.state.user?.id));
		window.location.replace('/');
	};

	return { onSignOut };
};
