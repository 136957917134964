import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { ChevronDownIcon, SpeechIcon } from '@motorway/mw-highway-code';

import { handleZendeskOpenCloseStateChange, triggerZendeskOpenCloseState } from '../ZendeskWidget.helpers';

import { UnreadMessagesIndicator } from './UnreadMessagesIndicator';

import styles from './LauncherButton.module.scss';

export const LauncherButton: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		triggerZendeskOpenCloseState('close');
	}, []);

	useEffect(() => {
		const handleOpenCloseStateChange = (isOpenFromWidget: boolean) => {
			if (isOpenFromWidget !== isOpen) {
				setIsOpen(isOpenFromWidget);
			}
		};
		handleZendeskOpenCloseStateChange(handleOpenCloseStateChange);
		triggerZendeskOpenCloseState(isOpen ? 'open' : 'close');
	}, [isOpen]);

	const onClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={styles.container}>
			<UnreadMessagesIndicator isOpen={isOpen} />
			<button aria-label={`${!isOpen ? 'Show' : 'Hide'} chat`} className={styles.button} onClick={onClick}>
				<div className={cx(styles.icon, { [styles.open]: isOpen, [styles.closed]: !isOpen })}>
					<ChevronDownIcon data-thc-icon="chevron" />
					<SpeechIcon data-thc-icon="speech" />
				</div>
			</button>
		</div>
	);
};
