import { useContext, useEffect } from 'react';

import { isUnsupportedBrowser } from 'Components/UnsupportedBrowser/UnsupportedBrowser.helpers';
import { AppContext } from 'Context/AppContext';

import useFeesStore from './FeesStore';

const FeesStoreLoader = (): null => {
	const appContext = useContext(AppContext);
	const setFees = useFeesStore((store) => store.setFees);

	const isUser = Boolean(appContext?.state?.user);
	const isSupportedBrowser = !isUnsupportedBrowser();

	useEffect(() => {
		if (isUser && isSupportedBrowser) {
			void setFees();
		}
	}, [isUser, setFees, isSupportedBrowser]);

	return null;
};

export default FeesStoreLoader;
