/* eslint-disable react/jsx-filename-extension */
/**
 * This page _app.js is loaded automatically by Next.js.
 */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorBoundary } from 'react-error-boundary';
import App from 'next/app';
import Head from 'next/head'; // https://github.com/vercel/next.js/issues/9647#issuecomment-583844001
import { config } from 'telefunc/client';

import 'Utilities/dayjs';

import ErrorFallbackContent from 'Components/ErrorFallbackContent/ErrorFallbackContent';
import { shouldForwardToUnsupportedBrowserPage } from 'Components/UnsupportedBrowser/UnsupportedBrowser.helpers';
import { AppProviders } from 'Context/AppProviders';
import { ALL_FEATURES_RELATED_COOKIES, FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';
import { getServerSideLocationData } from 'Context/LocationContext/LocationContext';
import { initDatadogBrowserLogs } from 'Services/datadog/datadogLogs';
import { initDatadogRum } from 'Services/datadog/datadogRum';
import { initGAUser, pageview, setGAUser } from 'Services/gtm';
import { logger } from 'Services/logger/logger';
import { trackSnowplowPageView } from 'Services/snowplow';
import useRouterStore from 'Stores/RouterStore/RouterStore';
import { NO_SCROLL_TO_TOP_ROUTES } from 'Utilities/consts';
import { handleReactErrorBoundaryError } from 'Utilities/error/handleReactErrorBoundaryError';
import fetchAppWideData from 'Utilities/fetchers/fetchAppWideData';
import { handleRefreshIfOutOfSyncBuild } from 'Utilities/handleRefreshIfOutOfSyncBuild';
import { getIsomorphicCookie, isDev, isProd, stringBooleanToBoolean } from 'Utilities/helpers';
import isStaging from 'Utilities/helpers/isStaging';
import { getInitialListType } from 'Utilities/helpers/listType.helpers';
import { handleWebVitals } from 'Utilities/helpers/webVitals';
import { IGNORED_FROM_HISTORY_ROUTES, SCROLL_TOP_USING_AS_PATH_CHANGE_ROUTES } from 'Utilities/routes';
import TestContentWithErrorThrowing from 'Utilities/TestContentWithErrorThrowing';
import { handleWindowCommunications } from 'Utilities/windowCommunicationChannel/windowCommunicationChannel.helpers';

import 'Utilities/styles/global.scss';
import 'Utilities/styles/main.scss';

// CAUTION: import doesn't work
// @ref: https://github.com/zeit/next-plugins/issues/266#issuecomment-422562693
require('../node_modules/@motorway/motorway-component-library/dist/index.css');
require('../node_modules/@motorway/motorway-storybook-cra/dist/themeDark.css');
require('../node_modules/@motorway/motorway-storybook-cra/dist/main.css');
require('../node_modules/react-popper-tooltip/dist/styles.css');

require('../node_modules/swiper/swiper-bundle.min.css');

export const reportWebVitals = (metric) => {
	const isWebVitalsLoggingEnabled = stringBooleanToBoolean(getIsomorphicCookie(FEATURES.webVitalsLogging));
	if (isWebVitalsLoggingEnabled) {
		handleWebVitals(metric);
	}
};

const isJestRunning = typeof jest !== 'undefined';

if (typeof window !== 'undefined') {
	config.telefuncUrl = '/api/_data';
}

export default class Pro extends App {
	constructor(...props) {
		super(...props);

		this.state = {
			hasError: false,
			windowError: undefined,
		};

		const { router, user } = this.props;

		this.windowLocationHref = router?.asPath ?? null;

		if (user) {
			setGAUser(user);
		}
	}

	componentDidMount() {
		const isDatadogRumEnabled = this.props.cookies?.[FEATURES.datadogRum];

		const { release, user } = this.props;

		if (isDatadogRumEnabled && (isStaging || isProd)) {
			initDatadogRum({ release, user });
		}

		initDatadogBrowserLogs({ release, user });
		initGAUser();
		pageview();
		handleWindowCommunications();

		if (!isProd) {
			// eslint-disable-next-line no-console
			const featureManagerLabel = 'Enable feature manager';
			/* eslint-disable no-console */
			console.groupCollapsed(featureManagerLabel);
			const enableFeatureManager = window.cookieStore
				? 'document.cookie="mwDisplayFeaturesManager=true; path=/";'
				: '(function(){document.cookie="mwDisplayFeaturesManager=true; path=/";window.location.reload();})();';

			console.info(
				`To enable feature manager paste the code below into the console: %c${enableFeatureManager}`,
				'background: #1D1D1B; color: #E5F4FB; font-family: Courier; padding: 4px 8px; border-radius: 4px; border: solid 1px #4A4A48; margin: 8px 0;',
			);
			console.groupEnd(featureManagerLabel);
			/* eslint-enable no-console */
		}

		// @ref https://github.com/zeit/next.js/issues/3303#issuecomment-354225323
		window.history.scrollRestoration = 'manual';

		// We have to add DOM polyfill in browser even if we have babel, because IE11 is stupid.
		// @ref https://stackoverflow.com/questions/46715190/error-in-ie-11-browser-exception-object-doesnt-support-property-or-method-m
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
		}

		if (!('scrollBehavior' in document.documentElement.style)) {
			import(/* webpackChunkName: "smoothscroll-polyfill" */ 'smoothscroll-polyfill')
				.then(({ default: SmoothScroll }) => {
					SmoothScroll.polyfill();
					window['smoothscroll-polyfill'] = true;
				})
				.catch((error) => `An error occurred while loading smoothscroll-polyfill; ${error}`);
		}

		const { router } = this.props;
		if (!router) {
			return;
		}

		this.prevRoute = router.route;
		this.prevRouteAsPath = router?.asPath;
		this.prevQuery = router?.query;
		router.events.on('routeChangeComplete', this.routeChange);

		window.onerror = (_message, _source, _lineno, _colno, error) => {
			this.setState({ windowError: error });
		};

		window.onunload = () => {
			this.setState({ hasError: false, windowError: undefined });
		};
	}

	static async getInitialProps({ Component, ctx }) {
		const url = ctx?.req?.url;
		const userAgent = ctx?.req?.headers['user-agent'];
		const isSSR = Boolean(ctx.req);

		const getIsomorphicUser = () => {
			try {
				/**
				 * We KNOW that NextJS hydrates data to FE using window.__NEXT_DATA__.
				 * Hydration always starts from server, which, if user is logged in
				 * will add user into propsFromServer object, which then is merged into context
				 * and send to frontend.
				 *
				 * This means that any browser run of getInitialProps WILL have access to
				 * window.__NEXT_DATA__, which means we can check for user in it!
				 */

				// eslint-disable-next-line no-underscore-dangle
				return isSSR ? ctx.req.user : window.__NEXT_DATA__.props.user;
			} catch (error) {
				const context = { req: ctx.req || {}, res: ctx.res || {} };

				logger.error({ context, error, message: 'Failed to load hydrate user', scope: 'App getIsomorphicUser' });

				return undefined;
			}
		};

		const user = getIsomorphicUser();
		const location = getServerSideLocationData({ pathname: url });

		const cookies = ALL_FEATURES_RELATED_COOKIES.reduce(
			(acc, cookieName) => ({
				...acc,
				[cookieName]: getIsomorphicCookie(cookieName, ctx.req) === 'true',
			}),
			{},
		);

		const isMirageEnabled = cookies?.[FEATURES.mirageServer];

		const pageContext = Object.assign(ctx, { isDev, isSSR, user });
		// appWideData is only available on server side, context values on other pages will have
		// undefined values for makes, models and savedFilters on client side routing.
		const appWideData = isSSR && user ? await fetchAppWideData(pageContext, isMirageEnabled) : {};

		const context = Object.assign(pageContext, { ...appWideData });

		if (url && shouldForwardToUnsupportedBrowserPage(url, userAgent)) {
			return context.res.redirect(307, '/unsupported-browser');
		}

		try {
			const pageProps = Component.getInitialProps ? await Component.getInitialProps(context) : {};

			const initialListType = getInitialListType({
				listType: context.query?.listType,
				saleTimes: appWideData.saleTimes,
			});

			return {
				env: process.env.NODE_ENV,
				listType: initialListType,
				pageProps,
				release: process.env.BUILD_ID,
				user,
				...appWideData,
				cookies,
				location,
			};
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('getInitialProps ERROR:', error);
			logger.error({ context, error, message: 'Failed to define page props', scope: 'App getInitialProps' });

			return { hasError: true };
		}
	}

	static getDerivedStateFromProps(props, state) {
		// If there was an error generated within getInitialProps, and we haven't
		// yet seen an error, we add it to this.state here
		return {
			hasError: props.hasError || state.hasError || false,
		};
	}

	static getDerivedStateFromError() {
		// React Error Boundary here allows us to set state flagging the error (and
		// later render a fallback UI).
		return { hasError: true };
	}

	// eslint-disable-next-line class-methods-use-this
	componentDidCatch(error, errorInfo) {
		logger.error({
			context: errorInfo,
			error,
			message: 'Error caught on App component',
			scope: 'App componentDidCatch',
		});
	}

	componentWillUnmount() {
		const { router } = this.props;

		router?.events?.off?.('routeChangeComplete', this.routeChange);
	}

	routeChange = () => {
		const { release, router } = this.props;
		const { pathname, search } = window.location;

		const windowUrl = `${pathname}${search}`;

		if (this.windowLocationHref !== windowUrl) {
			pageview();
			trackSnowplowPageView();
		}

		this.windowLocationHref = windowUrl;

		if (!router) {
			return;
		}

		handleRefreshIfOutOfSyncBuild({ clientBuildId: release, route: router.state.route });

		const isNewRoute =
			(router.route !== this.prevRoute && !IGNORED_FROM_HISTORY_ROUTES.includes(this.prevRoute)) ||
			(SCROLL_TOP_USING_AS_PATH_CHANGE_ROUTES.includes(router.route) && router.asPath !== this.prevRouteAsPath);

		if (isNewRoute) {
			useRouterStore.setState({
				prevQuery: this.prevQuery,
				prevRoute: this.prevRoute,
				prevRouteAsPath: this.prevRouteAsPath,
			});
		}

		// reset scroll to top on each route transition
		if (isNewRoute && !NO_SCROLL_TO_TOP_ROUTES.includes(router.route)) {
			window.scrollTo(0, 0);
		}

		this.prevRoute = router.route;
		this.prevRouteAsPath = router?.asPath;
		this.prevQuery = router?.query;
	};

	render() {
		let content;
		if (this.state.hasError) {
			content = <ErrorFallbackContent state={this.state?.windowError} />;
		} else if (isJestRunning) {
			content = <TestContentWithErrorThrowing {...this.props.pageProps} />;
		} else {
			content = <AppProviders {...this.props}>{super.render()}</AppProviders>;
		}

		return (
			<ErrorBoundary
				FallbackComponent={() => <ErrorFallbackContent state={this.state} />}
				onError={handleReactErrorBoundaryError}
				onReset={() => {
					this.setState({ hasError: false, windowError: undefined });
				}}
			>
				<Head>
					<meta content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" name="viewport" />
				</Head>
				{content}
			</ErrorBoundary>
		);
	}
}
