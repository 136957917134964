import noop from 'lodash/noop';

import { logger } from 'Services/logger/logger';
import { isSSR } from 'Utilities/helpers';
import { signInRedirect } from 'Utilities/helpers/signin';

import { SIGNIN_MESSAGE_TYPE, SIGNOUT_MESSAGE_TYPE } from './windowCommunicationChannel.consts';
import type { WindowCommunicationMessage, WindowCommunicationMessageTypeMap } from './windowCommunicationChannel.types';

// Ensure that the polyfill is used in the event that BroadcastChannel is not supported
// Polyfill will do nothing but will ensure we don't have any runtime errors
const polyfillWindowCommunicationChannel = {
	close: noop,
	onmessage: (ev) => noop(ev),
	onmessageerror: noop,
	postMessage: (message) => noop(message),
} as BroadcastChannel;

export const isBroadcastChannelSupported = () => {
	return !isSSR() && 'BroadcastChannel' in window;
};

export const windowCommunicationChannel = isBroadcastChannelSupported()
	? new BroadcastChannel('windowCommunicationChannel')
	: polyfillWindowCommunicationChannel;

// Message handlers
export const handlerWindowCommunicationMessages = (message: MessageEvent<WindowCommunicationMessage>) => {
	if (isSSR()) {
		const error = new Error('handlerWindowCommunicationMessages called on server side');
		logger.error({
			error,
			message: error.message,
			scope: 'handlerWindowCommunicationMessages',
		});
		return;
	}

	switch (message.data.type) {
		case SIGNOUT_MESSAGE_TYPE:
			window.location.reload();
			break;
		case SIGNIN_MESSAGE_TYPE: {
			signInRedirect(window.location.href);
			break;
		}
		default:
			logger.warn({
				context: {
					extra: message.data,
				},
				message: `Unknown message type`,
				scope: 'handlerWindowCommunicationMessages',
			});
			break;
	}
};

export const handleWindowCommunications = () => {
	windowCommunicationChannel.onmessage = (event) => handlerWindowCommunicationMessages(event);
};

// Message creators
export const createMessage = <T extends keyof WindowCommunicationMessageTypeMap>(
	type: T,
	payload: WindowCommunicationMessageTypeMap[T],
): { payload: WindowCommunicationMessageTypeMap[T]; type: T } => ({
	payload,
	type,
});

export const createSignOutMessage = (userId?: number) => createMessage(SIGNOUT_MESSAGE_TYPE, { userId });
export const createSignInMessage = (userId?: number) => createMessage(SIGNIN_MESSAGE_TYPE, { userId });
