import { DEFAULT_VEHICLE_LIST_ROUTE } from 'Utilities/consts';
import { createBrowserOnlyRedirectPath } from 'Utilities/routes.helpers';

import { getUrlParameterByName } from '.';

const NO_SELF_REDIRECT_URLS = ['/signin?redirect=%2Fsignup'];
/*
 * Settings default redirect route to auction list type as the component using this helper is a class component
 * which means its unable to use useVehicleListButtonConfig
 */
const DEFAULT_REDIRECT_ROUTE = DEFAULT_VEHICLE_LIST_ROUTE.as;

export const signInRedirect = (asPath: string): void => {
	const selfRedirectUrl = getUrlParameterByName('redirect', asPath) || DEFAULT_REDIRECT_ROUTE;
	const redirectUrl = NO_SELF_REDIRECT_URLS.includes(asPath) ? DEFAULT_REDIRECT_ROUTE : selfRedirectUrl;

	window?.location?.replace(createBrowserOnlyRedirectPath(redirectUrl, DEFAULT_REDIRECT_ROUTE));
};
