import { useEffect } from 'react';
import { MDText } from 'i18n-react';
import noop from 'lodash/noop';

import { Modal } from '@motorway/motorway-storybook-cra';

import {
	OtpFormContent,
	OtpFormContentBottom,
	OtpSuccessFormContent,
} from 'Components/AuthenticationFeedback/AuthenticationFeedback';
import OTPContent from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent';
import type { OTPContentProps } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import { OtpAction } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import { onRequestCatch } from 'Components/pages/VehiclePayment/components/OTPContent/OTPRequestCatch.helper';
import { GA_OTP_LABEL, OTP_ACTION } from 'Components/pages/VehiclePayment/VehiclePayment.const';
import Texts from 'Components/texts.json';
import {
	CLICK_SCHEMA,
	POPUP_APPEAR_SCHEMA,
	triggerOtpAnalyticsEvent,
} from 'Services/analytics/events/paymentEvents/otpSnowplowEvents';
import { confirmOTPPhoneNumber } from 'Services/api/otp';
import { useStoreOtpPhoneValidated } from 'Utilities/hooks/useStoreOtpPhoneValidated';
import useUser from 'Utilities/hooks/useUser';

import styles from './OtpSetupModal.module.scss';

const LocalT = new MDText(Texts);

export enum OtpSetupModalVariant {
	FORM = 'Form',
	SUCCESS = 'Success',
}
export const OtpSetupModal: React.FC<{
	onDismiss: () => void;
	onSuccess: () => void;
	show: boolean;
	variant?: OtpSetupModalVariant;
}> = ({ onDismiss, onSuccess = noop, show, variant }) => {
	const { otpPhone } = useUser();

	const endOfPhone = otpPhone?.slice?.(otpPhone.length - 3, otpPhone.length) || '';

	const [, setIsOtpPhoneValidated] = useStoreOtpPhoneValidated(false);
	useEffect(() => {
		if (!show) return;
		triggerOtpAnalyticsEvent({
			customData: { label: GA_OTP_LABEL.OTP_SETUP_SHOWN },
			name: OTP_ACTION.OTP_SETUP_SHOWN,
			schema: POPUP_APPEAR_SCHEMA,
		});
	}, [show]);

	if (!show) return null;

	const showSuccessOtpSetup = variant === OtpSetupModalVariant.SUCCESS;
	const setUpMFA: OTPContentProps['request'] = async (verificationCode) => {
		try {
			await confirmOTPPhoneNumber({ params: { otpAction: OtpAction.VERIFY_PHONE, verificationCode } });

			return () => {
				setIsOtpPhoneValidated(true);
				onSuccess();
			};
		} catch (error) {
			throw await onRequestCatch({ error });
		}
	};

	return (
		<Modal
			dismissModal={() => {
				triggerOtpAnalyticsEvent({
					customData: { label: GA_OTP_LABEL.OTP_SETUP_CLOSED },
					name: OTP_ACTION.OTP_SETUP_CLOSED,
					schema: CLICK_SCHEMA,
				});
				onDismiss();
			}}
			shouldCloseOnOverlayClick={false}
			size="small"
		>
			{showSuccessOtpSetup ? (
				<OtpSuccessFormContent />
			) : (
				<OTPContent
					bottomDesc={
						<div className={styles.bottomDesc}>
							<OtpFormContentBottom />
						</div>
					}
					desc={<OtpFormContent otpPhoneEnd={endOfPhone} />}
					logs={{ source: 'setup' }}
					request={setUpMFA}
					title={`${LocalT.translate('MFA.titleOTP')}`}
				/>
			)}
		</Modal>
	);
};
